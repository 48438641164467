import { SagaIterator } from '@redux-saga/core';
import { getLeads as getLeadsApi } from 'helpers';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {LeadsActionTypes } from './constants';
import {leadsApiResponseSuccess} from "./actions";

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* leadsInfo(): SagaIterator {
    try {
        const data = yield call(getLeadsApi);
        yield put({ type: LeadsActionTypes.SET_LEADS, data });

        yield put(leadsApiResponseSuccess(LeadsActionTypes.GET_LEADS, data));
    } catch (error: any) {}
}

export function* watchDriverInfo() {
    yield takeEvery(LeadsActionTypes.GET_LEADS, leadsInfo);
}

function* leadsSaga() {
    yield all([fork(watchDriverInfo)]);
}

export default leadsSaga;
