import { all } from 'redux-saga/effects';
import authSaga from './auth/saga';
import driverSaga from './driver/saga';
import layoutSaga from './layout/saga';
import brokerSaga from "./broker/saga";
import leadsSaga from "./leads/saga";
import trucksSaga from "./trucks/saga";
import trailersSaga from "./trailers/saga";
import portalsSaga from "./portals/saga";
import carrierSaga from "./carrier/saga";
import commonListSaga from "./commonList/saga";
import loadsSaga from "./loads/saga";

export default function* rootSaga() {
    yield all(
        [authSaga(),
            layoutSaga(),
            driverSaga(),
            brokerSaga(),
            leadsSaga(),
            trucksSaga(),
            trailersSaga(),
            portalsSaga(),
            carrierSaga(),
            commonListSaga(),
            loadsSaga()
        ]);
}
