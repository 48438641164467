import { AuthActionTypes } from './constants';

export type AuthActionType = {
    type:
        | AuthActionTypes.API_RESPONSE_SUCCESS
        | AuthActionTypes.API_RESPONSE_ERROR
        | AuthActionTypes.FORGOT_PASSWORD
        | AuthActionTypes.FORGOT_PASSWORD_CHANGE
        | AuthActionTypes.LOGIN_USER
        | AuthActionTypes.LOGOUT_USER
        | AuthActionTypes.RESET
        | AuthActionTypes.SIGNUP_USER;
    payload: {} | string;
};

type User = {
    id: number;
    email: string;
    username: string;
    password: string;
    firstName: string;
    middleName: string;
    lastName: string;
    role: string;
    token: string;
};

// common success
export const authApiResponseSuccess = (actionType: string, data: User | {}): AuthActionType => ({
    type: AuthActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const authApiResponseError = (actionType: string, error: string): AuthActionType => ({
    type: AuthActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

// export const loginUser = (email: string, password: string): AuthActionType => {
//     const url = `${config.API_URL}login`;
//     let result: AuthActionType = {type: AuthActionTypes.LOGIN_USER, payload: {}};
//     axios.post(url, {email, password}).then((res: any) => {
//         if(res.success) {
//             result = ({
//                 type: AuthActionTypes.LOGIN_USER,
//                 payload: { email, password },
//             })
//         }else {
//             result = ({
//                 type:AuthActionTypes.API_RESPONSE_ERROR,
//                 payload: { actionType: 'error', error: res.error },
//             })
//         }
//     }).catch(err => {
//         result = ({
//             type:AuthActionTypes.API_RESPONSE_ERROR,
//             payload: { actionType: 'error', err },
//         })
//     })

//     // const actionApi = API.post(url, {email, password});
//     // actionApi.then((res: any) => {
//     //     if(res.success) {
//     //         result = ({
//     //             type: AuthActionTypes.LOGIN_USER,
//     //             payload: { email, password },
//     //         })
//     //     }else {
//     //         result = ({
//     //             type:AuthActionTypes.API_RESPONSE_ERROR,
//     //             payload: { actionType: 'error', error: res.error },
//     //         })
//     //     }
//     // }).catch(err => {
//     //     result = ({
//     //         type:AuthActionTypes.API_RESPONSE_ERROR,
//     //         payload: { actionType: 'error', err },
//     //     })
//     // })

//     return result;

//     // return ({
//     //     type: AuthActionTypes.LOGIN_USER,
//     //     payload: { email, password },
//     // })
// };

export const loginUser = (email: string, password: string): AuthActionType => ({
    type: AuthActionTypes.LOGIN_USER,
    payload: { email, password },
});

export const logoutUser = (): AuthActionType => ({
    type: AuthActionTypes.LOGOUT_USER,
    payload: {},
});

export const signupUser = (fullname: string, email: string, password: string): AuthActionType => ({
    type: AuthActionTypes.SIGNUP_USER,
    payload: { fullname, email, password },
});

export const forgotPassword = (username: string): AuthActionType => ({
    type: AuthActionTypes.FORGOT_PASSWORD,
    payload: { username },
});

export const resetAuth = (): AuthActionType => ({
    type: AuthActionTypes.RESET,
    payload: {},
});
