import { SagaIterator } from '@redux-saga/core';
import {postCarriers as postCarriersApi} from 'helpers';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {CarrierActionTypes } from './constants';
import {carrierApiResponseSuccess} from "./actions";
// import {CarrierType} from "../../pages/apps/Carrier/model/carrieSetup";

type CarrierType = {
    payload: {
        name: string;
        dba_name: string;
        dot: number;
        mc: number;
        scac_number: number;
        fein_number: number;
        office_phone: string;
        office_fax: string;
        main_email: string;
        accounting_email: string;
        office_address1: string;
        office_address2: string;
        office_city: string;
        office_state: string;
        office_zip: string;
        mailling_address1: string;
        mailling_address2: string;
        mailling_city: string;
        mailling_state: string;
        mailling_zip: string;
    };
    type: string;
};

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* carrierInfo({
  payload: { name, dba_name, dot, mc, scac_number, fein_number, office_phone, office_fax, main_email, accounting_email,
      office_address1, office_address2, office_city, office_state, office_zip, mailling_address1, mailling_address2, mailling_city, mailling_state, mailling_zip,
    }, type
}: CarrierType): SagaIterator {
    try {
        const data = yield call(postCarriersApi, { name, dba_name, dot, mc, scac_number, fein_number, office_phone, office_fax, main_email, accounting_email,
            office_address1, office_address2, office_city, office_state, office_zip, mailling_address1, mailling_address2, mailling_city, mailling_state, mailling_zip,
        } );

        console.log('data',data);
        yield put({ type: CarrierActionTypes.SET_CARRIER, data });

        yield put(carrierApiResponseSuccess(CarrierActionTypes.GET_CARRIER, data));
    } catch (error: any) {}
}

export function* watchCarrierInfo() {
    yield takeEvery(CarrierActionTypes.SET_CARRIER, carrierInfo);
}

function* carrierSaga() {
    yield all([fork(watchCarrierInfo)]);
}

export default carrierSaga;
