import { PortalsActionTypes } from './constants';

export type PortalsActionType = {
    type:
        | PortalsActionTypes.API_RESPONSE_SUCCESS
        | PortalsActionTypes.API_RESPONSE_ERROR
        | PortalsActionTypes.GET_PORTALS
        | PortalsActionTypes.SET_PORTALS;
    payload: {} | string;
};

// common success
export const portalsApiResponseSuccess = (actionType: string, data: PortalsActionTypes | {}): PortalsActionType => ({
    type: PortalsActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

// common error
export const portalsApiResponseError = (actionType: string, error: string): PortalsActionType => ({
    type: PortalsActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getPortals = () => {
    return {
        type: PortalsActionTypes.GET_PORTALS,
        payload: {},
    };
};

export const setPortals = (actionType: string, data: PortalsActionTypes | []): PortalsActionType => {
    return {
        type: PortalsActionTypes.SET_PORTALS,
        payload: data,
    };
};
