import { APICore } from './apiCore';

const api = new APICore();

function getBroker(params: { page: number; page_size: number }) {
    const brokerURL =`trip/get-all-brokers`;
    return api.get(`${brokerURL}`, params);
}

export { getBroker };
