import { APICore } from './apiCore';

const api = new APICore();

function getOwnerList() {
    const baseUrl = 'owners';
    return api.get(`${baseUrl}`);
}

function getCarrierList() {
    const baseUrl = 'carriers';
    return api.get(`${baseUrl}`);
}

export { getOwnerList, getCarrierList };