import { SagaIterator } from '@redux-saga/core';
import { getPortals as getPortalsApi} from 'helpers';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {PortalsActionTypes } from './constants';
import {portalsApiResponseSuccess} from "./actions";

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* portalsInfo(): SagaIterator {
    try {
        const data = yield call(getPortalsApi);
        yield put({ type: PortalsActionTypes.SET_PORTALS, data });

        yield put(portalsApiResponseSuccess(PortalsActionTypes.GET_PORTALS, data));
    } catch (error: any) {}
}

export function* watchDriverInfo() {
    yield takeEvery(PortalsActionTypes.GET_PORTALS, portalsInfo);
}

function* portalsSaga() {
    yield all([fork(watchDriverInfo)]);
}

export default portalsSaga;
