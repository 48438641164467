import { APICore } from './apiCore';

const api = new APICore();

function getLoads() {
    const baseUrl = 'active-loads';
    return api.get(`${baseUrl}`);
}
function getCompletedLoads() {
    const baseUrl = 'completed-loads';
    return api.get(`${baseUrl}`);
}
export { getLoads, getCompletedLoads };
