import { TruckActionTypes } from './constants';
import {ActiveTrucksType} from "../../pages/apps/ActiveTrucks/model/activeTruck";

type TruckActionType = {
    type:
        | TruckActionTypes.API_RESPONSE_SUCCESS
        | TruckActionTypes.API_RESPONSE_ERROR
        | TruckActionTypes.GET_TRUCKS
        | TruckActionTypes.SET_TRUCKS
        | TruckActionTypes.GET_INACTIVE_TRUCKS
        | TruckActionTypes.SET_INACTIVE_TRUCKS
        | TruckActionTypes.GET_SINGLE_TRUCK
        | TruckActionTypes.SET_SINGLE_TRUCK
        | TruckActionTypes.REMOVE_SINGLE_TRUCK;
    payload: {
        actionType?: string;
        data?: ActiveTrucksType | {};
        error?: string;
    };
    data?: [];
};

const INIT_STATE = {
    trucks: [],
    inactiveTrucks:[],
    singleTruck:{},
    loading: false,
};

type StateType = {
    trucks?: ActiveTrucksType | {};
    inactiveTrucks?: ActiveTrucksType | {};
    singleTruck?:ActiveTrucksType | {},
    loading?: boolean;
};

const Trucks = (state: StateType = INIT_STATE, action: TruckActionType) => {
    switch (action.type) {
        case TruckActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {

                case TruckActionTypes.GET_TRUCKS: {
                    return {
                        ...state,
                        trucks: state.trucks || [],
                        loading: false,
                    };
                }

                case TruckActionTypes.GET_SINGLE_TRUCK: {
                    return {
                        ...state,
                        singleTruck: state.singleTruck || [],
                        loading: false,
                    };
                }

                default:
                    return { ...state };
            }


        case TruckActionTypes.GET_TRUCKS: {
            return {
                ...state,
                trucks: state.trucks || [],
                loading: true,
            };
        }

        case TruckActionTypes.GET_INACTIVE_TRUCKS: {
            return {
                ...state,
                inactiveTrucks: state.inactiveTrucks || [],
                loading: true,
            };
        }

        case TruckActionTypes.SET_TRUCKS: {
            return {
                ...state,
                trucks: action.data,
            };
        }

        case TruckActionTypes.SET_INACTIVE_TRUCKS: {
            return {
                ...state,
                inactiveTrucks: action.data,
            };
        }


        case TruckActionTypes.GET_SINGLE_TRUCK: {
            return {
                ...state,
                singleTruck: state.singleTruck || [],
                loading: true,
            };
        }

        case TruckActionTypes.SET_SINGLE_TRUCK: {
            return {
                ...state,
                singleTruck: action.data,
            };
        }

       case TruckActionTypes.REMOVE_SINGLE_TRUCK: {
            return {
                ...state,
                singleTruck:  {},
            };
        }

        default:
            return { ...state };
    }
};

export default Trucks;
