import {LoadsActionTypes} from "./constants";

export type LoadsActionType = {
    type:
        | LoadsActionTypes.API_RESPONSE_SUCCESS
        | LoadsActionTypes.API_RESPONSE_ERROR
        | LoadsActionTypes.GET_LOADS
        | LoadsActionTypes.SET_LOADS
        | LoadsActionTypes.GET_COMPLETED_LOADS
        | LoadsActionTypes.SET_COMPLETED_LOADS

    payload: {} | string;
};

// common success
export const loadsApiResponseSuccess = (actionType: string, data: LoadsActionTypes | {}): LoadsActionType => ({
    type: LoadsActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

// common error
export const loadsApiResponseError = (actionType: string, error: string): LoadsActionType => ({
    type: LoadsActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getLoads = () => {
    return {
        type: LoadsActionTypes.GET_LOADS,
        payload: {},
    };
};

export const setLoads = (actionType: string, data: LoadsActionTypes | []): LoadsActionType => {
    return {
        type: LoadsActionTypes.SET_LOADS,
        payload: data,
    };
};

export const setCompletedLoads= (actionType: string, inactiveData: LoadsActionTypes | []): LoadsActionType => {
    return {
        type: LoadsActionTypes.SET_COMPLETED_LOADS,
        payload: inactiveData,
    };
};

export const getCompletedLoads = () => {
    return {
        type: LoadsActionTypes.GET_COMPLETED_LOADS,
        payload: {},
    };
}

