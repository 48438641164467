import { APICore } from './apiCore';

const api = new APICore();

function getTrailers() {
    const baseUrl = 'trailer';
    return api.get(`${baseUrl}`);
}

function getSingleTrailer(params: { token: string }) {
    const baseUrl = `trailer/${params.token}`;
    return api.get(`${baseUrl}`);
}

function getInactiveTrailers() {
    const baseUrl = 'trailer/terminated';
    return api.get(`${baseUrl}`);
}

function storeFiles(params: any){
    const baseUrl = 'files';
    return api.createWithFile(`${baseUrl}`, params.payload);
}

function createTrailer(data: any) {
    const baseUrl = 'trailer';
    return api.create(`${baseUrl}`, data);
}

function updateTrailer(data: any) {
    const baseUrl = 'trailer';
    return api.update(`${baseUrl}`, data);
}

export { getTrailers, storeFiles, createTrailer, getInactiveTrailers, getSingleTrailer, updateTrailer };