import { TrailersActionTypes } from './constants';

export type TrailersActionType = {
    type:
        | TrailersActionTypes.API_RESPONSE_SUCCESS
        | TrailersActionTypes.API_RESPONSE_ERROR
        | TrailersActionTypes.GET_TRAILERS
        | TrailersActionTypes.SET_TRAILERS
        | TrailersActionTypes.STORE_FILES
        | TrailersActionTypes.CREATE_TRAILER
        | TrailersActionTypes.SET_INACTIVE_TRAILERS
        | TrailersActionTypes.GET_INACTIVE_TRAILERS
        | TrailersActionTypes.SET_SINGLE_TRAILER
        | TrailersActionTypes.GET_SINGLE_TRAILER;
    payload: {} | string;
};

// common success
export const trailersApiResponseSuccess = (actionType: string, data: TrailersActionTypes | {}): TrailersActionType => ({
    type: TrailersActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

// common error
export const trailersApiResponseError = (actionType: string, error: string): TrailersActionType => ({
    type: TrailersActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const createTrailer = (data: any) => {
    return {
        type: TrailersActionTypes.CREATE_TRAILER,
        payload: data
    }
}

export const getTrailers = () => {
    return {
        type: TrailersActionTypes.GET_TRAILERS,
        payload: {},
    };
};

export const setTrailers = (actionType: string, data: TrailersActionTypes | []): TrailersActionType => {
    return {
        type: TrailersActionTypes.SET_TRAILERS,
        payload: data,
    };
};


export const storeFiles = (files: {file: File}) => {
    return {
        type: TrailersActionTypes.STORE_FILES,
        payload: files
    };
}

export const getInactiveTrailers = () => {
    return {
        type: TrailersActionTypes.GET_INACTIVE_TRAILERS,
        payload: {},
    };
};

export const setActiveTrailers = (actionType: string, inactiveData: TrailersActionTypes | []): TrailersActionType => {
    return {
        type: TrailersActionTypes.SET_INACTIVE_TRAILERS,
        payload: inactiveData,
    };
};

export const setSingleTrailer = (actionType: string, data: TrailersActionTypes | []): TrailersActionType => {
    return {
        type: TrailersActionTypes.SET_SINGLE_TRAILER,
        payload: data,
    };
};


export const getSingleTrailer = (token: string) => {
    return {
        type: TrailersActionTypes.GET_SINGLE_TRAILER,
        payload: {token},
    };
};

export const removeSingleTrailer = () => {
    return {
        type: TrailersActionTypes.REMOVE_SINGLE_TRAILER,
        payload: {},
    };
};


export const updateTrailer = (data: any) => {
    return {
        type: TrailersActionTypes.UPDATE_TRAILER,
        payload: data
    }
}