import { SagaIterator } from '@redux-saga/core';
import {getCarrierList, getOwnerList} from 'helpers';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {CommonLisTypes} from "./constants";
import {commonListApiResponseSuccess} from "./actions";

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* ownerListInfo(): SagaIterator {
    try {
        const data = yield call(getOwnerList);
        yield put({ type: CommonLisTypes.SET_OWNERS, data });

        yield put(commonListApiResponseSuccess(CommonLisTypes.GET_OWNERS, data));
    } catch (error: any) {}
}

function* carrierListInfo(): SagaIterator {
    try {
        const data = yield call(getCarrierList);
        yield put({type: CommonLisTypes.SET_CARRIERS, data});
        yield put(commonListApiResponseSuccess(CommonLisTypes.GET_CARRIERS, data?.data?.data));
    } catch (error: any) {
        console.log('data err', error);
    }
}


export function* watchOwnerListInfo() {
    yield takeEvery(CommonLisTypes.GET_OWNERS, ownerListInfo);
}

export function* watchCarrierListInfo(){
    yield takeEvery(CommonLisTypes.GET_CARRIERS, carrierListInfo)
}

function* commonListSaga() {
    yield all([fork(watchOwnerListInfo), fork(watchCarrierListInfo),]);
}

export default commonListSaga;
