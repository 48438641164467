import { SagaIterator } from '@redux-saga/core';
import {
    getTrucks as getTrucksApi,
    createTruck as createTruckApi,
    getInactiveTrucks,
    getSingleTrucks,
    updateTruck
} from 'helpers';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {trucksApiResponseSuccess} from "./actions";
import {TruckActionTypes} from "./constants";
import {TokenData} from "../../utils/models/globalModel";

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* truckInfo(): SagaIterator {
    try {
        const data = yield call(getTrucksApi);
        yield put({ type: TruckActionTypes.SET_TRUCKS, data });

        yield put(trucksApiResponseSuccess(TruckActionTypes.GET_TRUCKS, data));
    } catch (error: any) {}
}

function* singleTruckInfo({ payload: {token},type }: TokenData): SagaIterator {
    try {
        const data = yield call(getSingleTrucks, { token});

        yield put({type: TruckActionTypes.SET_SINGLE_TRUCK, data});
        yield put(trucksApiResponseSuccess(TruckActionTypes.GET_SINGLE_TRUCK, data?.data?.data));
    } catch (error: any) {
        yield put(trucksApiResponseSuccess(TruckActionTypes.GET_SINGLE_TRUCK, error));
    }
}

function* inactiveTruckInfo(): SagaIterator {
    try {
        const data = yield call(getInactiveTrucks);
        yield put({type: TruckActionTypes.SET_INACTIVE_TRUCKS, data});
        yield put(trucksApiResponseSuccess(TruckActionTypes.GET_INACTIVE_TRUCKS, data?.data?.data));
    } catch (error: any) {
        console.log('data err', error);
    }
}

function* createTruck({payload}: any):SagaIterator{
    try {
        const response = yield call(createTruckApi, payload);
        console.log(response);
    } catch (error) {
        console.log(error)   
    }
}

function* updateTrucks({payload}: any):SagaIterator{
    try {
        const response = yield call(updateTruck, payload);
        console.log(response);
    } catch (error) {
        console.log(error)
    }
}

export function* watchTruckInfo() {
    yield takeEvery(TruckActionTypes.GET_TRUCKS, truckInfo);
}

export function* watchCreateTruck(){
    yield takeEvery(TruckActionTypes.CREATE_TRUCK, createTruck)
}

export function* watchInactiveTruck(){
    yield takeEvery(TruckActionTypes.GET_INACTIVE_TRUCKS, inactiveTruckInfo)
}

export function* watchSingleTruck(){
    yield takeEvery(TruckActionTypes.GET_SINGLE_TRUCK, singleTruckInfo)
}

export function* watchUpdateTruck(){
    yield takeEvery(TruckActionTypes.UPDATE_TRUCK, updateTrucks)
}

function* trucksSaga() {
    yield all([fork(watchTruckInfo), fork(watchCreateTruck),fork(watchInactiveTruck), fork(watchSingleTruck), fork(watchUpdateTruck)]);
}

export default trucksSaga;
