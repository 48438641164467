import { combineReducers } from 'redux';
import Auth from './auth/reducers';
import Layout from './layout/reducers';
import Broker from './broker/reducers';
import Leads from "./leads/reducers";
import Trucks from "./trucks/reducers";
import Trailers from "./trailers/reducers";
import Portals from "./portals/reducers";
import Carriers from "./carrier/reducers";
import CommonList from "./commonList/reducers";
import Drivers from "./driver/reducers";
import Loads from './loads/reducers';


export default combineReducers({
    Auth,
    Layout,
    Drivers,
    Broker,
    Leads,
    Trucks,
    Trailers,
    Portals,
    Carriers,
    CommonList,
    Loads
});
