import { LoadsActionTypes } from './constants';

type LoadsActionType = {
    type:
        | LoadsActionTypes.API_RESPONSE_SUCCESS
        | LoadsActionTypes.API_RESPONSE_ERROR
        | LoadsActionTypes.GET_LOADS
        | LoadsActionTypes.SET_LOADS
        | LoadsActionTypes.GET_COMPLETED_LOADS
        | LoadsActionTypes.SET_COMPLETED_LOADS
    payload: {
        actionType?: string;
        data?: any | {};
        error?: string;
    };
    data?: [];
};

const INIT_STATE = {
    activeLoads: [],
    completedLoads: [],
    loading: false,
};

type StateType = {
    activeLoads?: any | {};
    completedLoads?: any | {};
    loading?: boolean;
};

const Loads = (state: StateType = INIT_STATE, action: LoadsActionType) => {
    switch (action.type) {
        case LoadsActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case LoadsActionTypes.GET_LOADS: {
                    return {
                        ...state,
                        activeLoads: state.activeLoads || [],
                        loading: false,
                    };
                }

                default:
                    return { ...state };
            }


        case LoadsActionTypes.GET_LOADS: {
            return {
                ...state,
                activeLoads: state.activeLoads || [],
                loading: true,
            };
        }

        case LoadsActionTypes.SET_LOADS: {
            return {
                ...state,
                activeLoads: action.data,
            };
        }

        case LoadsActionTypes.GET_COMPLETED_LOADS: {
            return {
                ...state,
                completedLoads: state.completedLoads || [],
                loading: true,
            };
        }

        case LoadsActionTypes.SET_COMPLETED_LOADS: {
            return {
                ...state,
                completedLoads: action.data,
            };
        }

        default:
            return { ...state };
    }
};

export default Loads;
