import { APICore } from './apiCore';

const api = new APICore();

function getTrucks() {
    const baseUrl = 'truck';
    return api.get(`${baseUrl}`);
}

function getSingleTrucks(params: { token: string }) {
    const baseUrl = `truck/${params.token}`;
    return api.get(`${baseUrl}`);
}

function getInactiveTrucks() {
    const baseUrl = 'truck/terminated';
    return api.get(`${baseUrl}`);
}

function createTruck(data: any) {
    const baseUrl = 'truck';
    return api.create(`${baseUrl}`, data);
}

function updateTruck(data: any) {
    const baseUrl = 'truck';
    return api.update(`${baseUrl}`, data);
}

export { getTrucks, createTruck, getInactiveTrucks,getSingleTrucks, updateTruck};
