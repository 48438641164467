import {DriverActionTypes} from './constants';

export type DriverActionType = {
    type:
        | DriverActionTypes.API_RESPONSE_SUCCESS
        | DriverActionTypes.API_RESPONSE_ERROR
        | DriverActionTypes.GET_DRIVER
        | DriverActionTypes.SET_DRIVER
        | DriverActionTypes.SET_INACTIVE_DRIVERS
        | DriverActionTypes.GET_INACTIVE_DRIVERS;
    payload: {} | string;
};

// common success
export const driverApiResponseSuccess = (actionType: string, data: DriverActionTypes | {}): DriverActionType => ({
    type: DriverActionTypes.API_RESPONSE_SUCCESS,
    payload: {actionType, data},
});


export const getDrivers = () => {
    return {
        type: DriverActionTypes.GET_DRIVER,
        payload: {},
    };
};

export const setDrivers = (actionType: string, data: DriverActionTypes | []): DriverActionType => {
    return {
        type: DriverActionTypes.SET_DRIVER,
        payload: data,
    };
};


export const setInactiveDrivers = (actionType: string, inactiveData: DriverActionTypes | []): DriverActionType => {
    return {
        type: DriverActionTypes.SET_INACTIVE_DRIVERS,
        payload: inactiveData,
    };
};

export const getInactiveDrivers = () => {
    return {
        type: DriverActionTypes.GET_INACTIVE_DRIVERS,
        payload: {},
    };
};

export const createDriver = (data: any) => {
    return {
        type: DriverActionTypes.CREATE_DRIVER,
        payload: data
    }
}