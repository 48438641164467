function getLeads() {
    // const baseUrl = 'type url';
    // return api.create(`${baseUrl}`, params);
    return [
        {
            id: 1,
            name: 'CONNECT US LLC',
            dot: 435521,
            poc: 'MIKE WIZ',
            phone: '756421',
            promo: 756421,
        },
        {
            id: 2,
            name: 'CONNECT US LLC',
            dot: 435521,
            poc: 'MIKE WIZ',
            phone: '756421',
            promo: 756422,
        },
        {
            id: 3,
            name: 'CONNECT US LLC',
            dot: 435521,
            poc: 'MIKE WIZ',
            phone: '756421',
            promo: 756423,
        },
        {
            id: 4,
            name: 'CONNECT US LLC',
            dot: 435521,
            poc: 'MIKE WIZ',
            phone: '756421',
            promo: 756424,
        },

    ];
}

export { getLeads };
