import { SagaIterator } from '@redux-saga/core';
import {
    createTrailer as createTrailerApi, getInactiveTrailers, getSingleTrailer,
    getTrailers as getTrailersApi,
    storeFiles as storeFilesApi,
    updateTrailer as updateTrailerApi
} from 'helpers';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {TrailersActionTypes } from './constants';
import {trailersApiResponseError, trailersApiResponseSuccess} from "./actions";
import { FileType } from 'components';
import {TokenData} from "../../utils/models/globalModel";

type StoreFilesData = {
    payload: {
        files: FileType[];
    };
    type: string;
};

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* sagaInfoInfo(): SagaIterator {
    try {
        const data = yield call(getTrailersApi);
        yield put({ type: TrailersActionTypes.SET_TRAILERS, data });

        yield put(trailersApiResponseSuccess(TrailersActionTypes.GET_TRAILERS, data));
    } catch (error: any) {}
}

function* singleTrailerInfo({ payload: {token},type }: TokenData): SagaIterator {
    try {
        const data = yield call(getSingleTrailer,{ token});

        yield put({type: TrailersActionTypes.SET_SINGLE_TRAILER, data});
        yield put(trailersApiResponseSuccess(TrailersActionTypes.GET_SINGLE_TRAILER, data?.data?.data));
    } catch (error: any) {
        yield put(trailersApiResponseError(TrailersActionTypes.GET_SINGLE_TRAILER, error));
    }
}

function* storeFiles({ payload, type }: StoreFilesData): SagaIterator{
    try {
        const response = yield call(storeFilesApi, {payload});
        console.log(response);
    } catch (error: any) {
        console.log(error)
    }
}

function* inactiveTrailerInfo(): SagaIterator {
    try {
        const data = yield call(getInactiveTrailers);
        yield put({type: TrailersActionTypes.SET_INACTIVE_TRAILERS, data});
        yield put(trailersApiResponseSuccess(TrailersActionTypes.GET_INACTIVE_TRAILERS, data?.data?.data));
    } catch (error: any) {
        console.log('data err', error);
    }
}

function* createTrailer({payload}: any):SagaIterator{
    try {
        const response = yield call(createTrailerApi, payload)
        console.log('response',response)
    } catch (error: any) {
        yield put(trailersApiResponseError(TrailersActionTypes.CREATE_TRAILER, error));
    }
}

function* updateTrailers({payload}: any):SagaIterator{
    try {
        const response = yield call(updateTrailerApi, payload)
        console.log('response',response)
    } catch (error: any) {
        console.log('data err', error);
    }
}

export function* watchCreateTrailer(){
    yield takeEvery(TrailersActionTypes.CREATE_TRAILER, createTrailer)
}

export function* watchTrailerInfo() {
    yield takeEvery(TrailersActionTypes.GET_TRAILERS, sagaInfoInfo);
}

export function* watchStoreFiles() {
    yield takeEvery(TrailersActionTypes.STORE_FILES, storeFiles);
}

export function* watchInactiveTrailer(){
    yield takeEvery(TrailersActionTypes.GET_INACTIVE_TRAILERS, inactiveTrailerInfo)
}

export function* watchSingleTrailer(){
    yield takeEvery(TrailersActionTypes.GET_SINGLE_TRAILER, singleTrailerInfo)
}

export function* watchUpdateTrailer(){
    yield takeEvery(TrailersActionTypes.UPDATE_TRAILER, updateTrailers)
}


function* trailersSaga() {
    yield all([fork(watchCreateTrailer),
        fork(watchTrailerInfo),
        fork(watchStoreFiles),
        fork(watchInactiveTrailer),
        fork(watchSingleTrailer),
        fork(watchUpdateTrailer)
    ]);
}

export default trailersSaga;
