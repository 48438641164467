import { AllRoutes } from './index';
import {BrowserRouter } from 'react-router-dom';

const Routes = () => {
    return (
        <BrowserRouter>
            <AllRoutes />
        </BrowserRouter>
    );
};

export default Routes;
