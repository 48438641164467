import { BrokerActionTypes } from './constants';
import {Employee} from "../../pages/trip/addtrip/broker/types";

type BrokerActionType = {
    type:
        | BrokerActionTypes.API_RESPONSE_SUCCESS
        | BrokerActionTypes.API_RESPONSE_ERROR
        | BrokerActionTypes.GET_BROKER
        | BrokerActionTypes.SET_BROKER;
    payload: {
        actionType?: string;
        data?: Employee | {};
        error?: string;
    };
    data?: [];
};

const INIT_STATE = {
    brokers: {},
    loading: false,
};

type StateType = {
    brokers?: Employee | {};
    loading?: boolean;
};

const Broker = (state: StateType = INIT_STATE, action: BrokerActionType) => {
    switch (action.type) {
        case BrokerActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case BrokerActionTypes.GET_BROKER: {
                    return {
                        ...state,
                        brokers: state.brokers || [],
                        loading: false,
                    };
                }

                default:
                    return { ...state };
            }


        case BrokerActionTypes.GET_BROKER: {
            return {
                ...state,
                loading: true,
            };
        }

        case BrokerActionTypes.SET_BROKER: {
            return {
                ...state,
                brokers: action.data,
            };
        }

        default:
            return { ...state };
    }
};

export default Broker;
