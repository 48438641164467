import { CommonLisTypes } from './constants';

export type CommonLisType = {
    type:
        | CommonLisTypes.API_RESPONSE_SUCCESS
        | CommonLisTypes.API_RESPONSE_ERROR
        | CommonLisTypes.GET_OWNERS
        | CommonLisTypes.SET_OWNERS
        | CommonLisTypes.GET_CARRIERS
        | CommonLisTypes.SET_CARRIERS;

    payload: {} | string;
};


// common success
export const commonListApiResponseSuccess = (actionType: string, data: CommonLisTypes | {}): CommonLisType => ({
    type: CommonLisTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

// common error
export const commonListApiResponseError = (actionType: string, error: string): CommonLisType => ({
    type: CommonLisTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getOwners = () => {
    return {
        type: CommonLisTypes.GET_OWNERS,
        payload: {},
    };
};

export const setOwners = (actionType: string, data: CommonLisTypes | []): CommonLisType => {
    return {
        type: CommonLisTypes.SET_OWNERS,
        payload: data,
    };
};

export const getCarriers = () => {
    return {
        type: CommonLisTypes.GET_CARRIERS,
        payload: {},
    };
};

export const setCarriers = (actionType: string, data: CommonLisTypes | []): CommonLisType => {
    return {
        type: CommonLisTypes.SET_CARRIERS,
        payload: data,
    };
};