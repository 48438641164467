import { CarrierActionTypes } from './constants';
import {CarrierType} from "../../pages/apps/Carrier/model/carrieSetup";

type CarrierActionType = {
    type:
        | CarrierActionTypes.API_RESPONSE_SUCCESS
        | CarrierActionTypes.API_RESPONSE_ERROR
        | CarrierActionTypes.GET_CARRIER
        | CarrierActionTypes.SET_CARRIER;
    payload: {
        actionType?: string;
        data?: CarrierType | {};
        error?: string;
    };
    data?: [];
};

const INIT_STATE = {
    carriers: [],
    loading: false,
};

type StateType = {
    carriers?: CarrierType | {};
    loading?: boolean;
};

const Carriers = (state: StateType = INIT_STATE, action: CarrierActionType) => {
    switch (action.type) {
        case CarrierActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case CarrierActionTypes.GET_CARRIER: {
                    return {
                        ...state,
                        carriers: state.carriers || [],
                        loading: false,
                    };
                }

                default:
                    return { ...state };
            }


        case CarrierActionTypes.GET_CARRIER: {
            return {
                ...state,
                carriers: state.carriers || [],
                loading: true,
            };
        }

        case CarrierActionTypes.SET_CARRIER: {
            return {
                ...state,
                carriers: action.data,
            };
        }

        default:
            return { ...state };
    }
};

export default Carriers;
