import { SagaIterator } from '@redux-saga/core';
import { getBroker as getBrokerApi } from 'helpers';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { BrokerActionTypes } from './constants';
import {brokerApiResponseSuccess} from "./actions";

type BrokerData = {
    payload: {
        page: number;
        page_size: number;
    };
    type: string;
};

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* brokerInfo({ payload: { page, page_size }, type }: BrokerData): SagaIterator {
    try {
        const data = yield call(getBrokerApi,{ page, page_size });
        yield put({ type: BrokerActionTypes.SET_BROKER, data });

        yield put(brokerApiResponseSuccess(BrokerActionTypes.GET_BROKER, data));
    } catch (error: any) {}
}

export function* watchDriverInfo() {
    yield takeEvery(BrokerActionTypes.GET_BROKER, brokerInfo);
}

function* brokerSaga() {
    yield all([fork(watchDriverInfo)]);
}

export default brokerSaga;
