import { TruckActionTypes } from './constants';

export type TruckActionType = {
    type:
        | TruckActionTypes.API_RESPONSE_SUCCESS
        | TruckActionTypes.API_RESPONSE_ERROR
        | TruckActionTypes.GET_TRUCKS
        | TruckActionTypes.SET_TRUCKS
        | TruckActionTypes.SET_INACTIVE_TRUCKS
        | TruckActionTypes.GET_SINGLE_TRUCK
        | TruckActionTypes.SET_SINGLE_TRUCK
        | TruckActionTypes.UPDATE_TRUCK;

    payload: {} | string;
};

export type FilesUploadType = {
    name: string,
    files: []
}

// common success
export const trucksApiResponseSuccess = (actionType: string, data: TruckActionTypes | {}): TruckActionType => ({
    type: TruckActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

// common error
export const trucksApiResponseError = (actionType: string, error: string): TruckActionType => ({
    type: TruckActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getTrucks = () => {
    return {
        type: TruckActionTypes.GET_TRUCKS,
        payload: {},
    };
};

export const getInactiveTrucks = () => {
    return {
        type: TruckActionTypes.GET_INACTIVE_TRUCKS,
        payload: {},
    };
};

export const createTruck = (data: any) => {
    return {
        type: TruckActionTypes.CREATE_TRUCK,
        payload: data
    }
}

export const setTrucks = (actionType: string, data: TruckActionTypes | []): TruckActionType => {
    return {
        type: TruckActionTypes.SET_TRUCKS,
        payload: data,
    };
};

export const setActiveTrucks = (actionType: string, inactiveData: TruckActionTypes | []): TruckActionType => {
    return {
        type: TruckActionTypes.SET_INACTIVE_TRUCKS,
        payload: inactiveData,
    };
};


export const setSingleTrucks = (actionType: string, data: TruckActionTypes | []): TruckActionType => {
    return {
        type: TruckActionTypes.SET_SINGLE_TRUCK,
        payload: data,
    };
};


export const getSingleTrucks = (token: string) => {
    return {
        type: TruckActionTypes.GET_SINGLE_TRUCK,
        payload: {token},
    };
};

export const removeSingleTruck = () => {
    return {
        type: TruckActionTypes.REMOVE_SINGLE_TRUCK,
        payload: {},
    };
};


export const updateTruck = (data: string) => {
    return {
        type: TruckActionTypes.UPDATE_TRUCK,
        payload: data,
    };
};