import { PortalsActionTypes } from './constants';
import {PortalTypes} from "../../pages/apps/ThirdPartyPortal/model/thirdPartyPortal";

type PortalsActionType = {
    type:
        | PortalsActionTypes.API_RESPONSE_SUCCESS
        | PortalsActionTypes.API_RESPONSE_ERROR
        | PortalsActionTypes.GET_PORTALS
        | PortalsActionTypes.SET_PORTALS;
    payload: {
        actionType?: string;
        data?: PortalTypes | {};
        error?: string;
    };
    data?: [];
};

const INIT_STATE = {
    portals: [],
    loading: false,
};

type StateType = {
    portals?: PortalTypes | {};
    loading?: boolean;
};

const Portals = (state: StateType = INIT_STATE, action: PortalsActionType) => {
    switch (action.type) {
        case PortalsActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case PortalsActionTypes.GET_PORTALS: {
                    return {
                        ...state,
                        portals: state.portals || [],
                        loading: false,
                    };
                }

                default:
                    return { ...state };
            }


        case PortalsActionTypes.GET_PORTALS: {
            return {
                ...state,
                portals: state.portals || [],
                loading: true,
            };
        }

        case PortalsActionTypes.SET_PORTALS: {
            return {
                ...state,
                portals: action.data,
            };
        }

        default:
            return { ...state };
    }
};

export default Portals;
