import { BrokerActionTypes } from './constants';

export type BrokerActionType = {
    type:
        | BrokerActionTypes.API_RESPONSE_SUCCESS
        | BrokerActionTypes.API_RESPONSE_ERROR
        | BrokerActionTypes.GET_BROKER
        | BrokerActionTypes.SET_BROKER;
    payload: {} | string;
};

// common success
export const brokerApiResponseSuccess = (actionType: string, data: BrokerActionTypes | {}): BrokerActionType => ({
    type: BrokerActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

// common error
export const brokerApiResponseError = (actionType: string, error: string): BrokerActionType => ({
    type: BrokerActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getBrokers = ( page: number, page_size: number ) => {
    return {
        type: BrokerActionTypes.GET_BROKER,
        payload: { page, page_size },
    };
};

export const setBrokers = (actionType: string, data: BrokerActionTypes | []): BrokerActionType => {
    return {
        type: BrokerActionTypes.SET_BROKER,
        payload: data,
    };
};
