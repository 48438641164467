import { LeadsActionTypes } from './constants';
import {ActiveLeadsType} from "../../pages/trip/addtrip/broker/types";

type BrokerActionType = {
    type:
        | LeadsActionTypes.API_RESPONSE_SUCCESS
        | LeadsActionTypes.API_RESPONSE_ERROR
        | LeadsActionTypes.GET_LEADS
        | LeadsActionTypes.SET_LEADS;
    payload: {
        actionType?: string;
        data?: ActiveLeadsType | {};
        error?: string;
    };
    data?: [];
};

const INIT_STATE = {
    leads: [],
    loading: false,
};

type StateType = {
    leads?: ActiveLeadsType | {};
    loading?: boolean;
};

const Leads = (state: StateType = INIT_STATE, action: BrokerActionType) => {
    switch (action.type) {
        case LeadsActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case LeadsActionTypes.GET_LEADS: {
                    return {
                        ...state,
                        leads: state.leads || [],
                        loading: false,
                    };
                }

                default:
                    return { ...state };
            }


        case LeadsActionTypes.GET_LEADS: {
            return {
                ...state,
                leads: state.leads || [],
                loading: true,
            };
        }

        case LeadsActionTypes.SET_LEADS: {
            return {
                ...state,
                leads: action.data,
            };
        }

        default:
            return { ...state };
    }
};

export default Leads;
