import { APICore } from './apiCore';
import { CarrierType } from "../../pages/apps/Carrier/model/carrieSetup";

const api = new APICore();

function postCarriers(params: CarrierType) {
    // return true;
    const baseUrl = 'add-carrier-attributes';
    return api.create(`${baseUrl}`, params);
}

export { postCarriers };