import React, {Suspense} from 'react';
import {useRoutes} from 'react-router-dom';
import {DefaultLayout, VerticalLayout, HorizontalLayout, DetachedLayout, FullLayout} from 'layouts';
import PrivateRoute from './PrivateRoute';
import Root from './Root';
import {LayoutTypes} from 'appConstants';
import {useRedux} from 'hooks';

// lazy load all the views
const Dashboard = React.lazy(() => import('pages/dashboard/Ecommerce'));

const Compensation = React.lazy(() => import('pages/compensation'));
const ManageTeam = React.lazy(() => import('pages/manageTeam'));
const ActiveLoads = React.lazy(() => import('pages/activeloads'));

const Broker = React.lazy(() => import('pages/trip/addtrip/broker'));
const NewBroker = React.lazy(() => import('pages/trip/addtrip/broker/NewBroker'));
const NewContact = React.lazy(() => import('pages/trip/addtrip/broker/NewContact'));
const FindAgent = React.lazy(() => import('pages/trip/addtrip/broker/FindAgent'));
const SelectedBroker = React.lazy(() => import('pages/trip/addtrip/broker/SelectedBroker'));

const Commodit = React.lazy(() => import('pages/trip/addtrip/commodity'));
const Pickup = React.lazy(() => import('pages/trip/addtrip/pickup'));
const Delivery = React.lazy(() => import('pages/trip/addtrip/delivery'));
const Submit = React.lazy(() => import('pages/trip/addtrip/submit'));

//active trips
const Info = React.lazy(() => import('pages/trip/activetrips/info'));
const Route = React.lazy(() => import('pages/trip/activetrips/route'));
const Commodity = React.lazy(() => import('pages/trip/activetrips/commodity'));
const Permits = React.lazy(() => import('pages/trip/activetrips/permits'));
const Invo = React.lazy(() => import('pages/trip/activetrips/invoice'));

// auth
const Login = React.lazy(() => import('pages/account/Login'));
const Register = React.lazy(() => import('pages/account/register'));
const Logout = React.lazy(() => import('pages/account/Logout'));
const Confirm = React.lazy(() => import('pages/account/Confirm'));
const ForgetPassword = React.lazy(() => import('pages/account/ForgetPassword'));
const LockScreen = React.lazy(() => import('pages/account/LockScreen'));

// apps
const CalendarApp = React.lazy(() => import('pages/apps/Calendar'));
const ProjectGannt = React.lazy(() => import('pages/apps/Projects/Gantt'));

// - chat
const ChatApp = React.lazy(() => import('pages/apps/Chat/'));

// - email
const Inbox = React.lazy(() => import('pages/apps/Email/Inbox'));
const EmailDetail = React.lazy(() => import('pages/apps/Email/Detail'));

// - social
const SocialFeed = React.lazy(() => import('pages/apps/SocialFeed/'));

const FileManager = React.lazy(() => import('pages/apps/FileManager'));

// pages
const Profile = React.lazy(() => import('pages/profile'));
const Profile2 = React.lazy(() => import('pages/profile2'));
const ErrorPageNotFound = React.lazy(() => import('pages/error/PageNotFound'));
const ServerError = React.lazy(() => import('pages/error/ServerError'));

// - other
const FAQ = React.lazy(() => import('pages/other/FAQ'));
const Maintenance = React.lazy(() => import('pages/other/Maintenance'));
const Landing = React.lazy(() => import('pages/landing/'));

const Carrier = React.lazy(() => import('pages/apps/Carrier'));
const ThirdPartyPortal = React.lazy(() => import('pages/apps/ThirdPartyPortal'));
const ActiveTrailers = React.lazy(() => import('pages/apps/ActiveTrailers'));
const ActiveTrucks = React.lazy(() => import('pages/apps/ActiveTrucks'));
const ActiveLeads = React.lazy(() => import('pages/apps/ActiveLeads'));
const ActiveDrivers = React.lazy(() => import('pages/apps/ActiveDrivers'));
const ActiveDriverManage = React.lazy(() => import('pages/apps/ActiveDriverManage'));
// const SingleActiveDriver = React.lazy(() => import('pages/apps/ActiveDriverManage:id'));

// maps
const loading = () => <div className=""></div>;

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({component: Component}: LoadComponentProps) => (
    <Suspense fallback={loading()}>
        <Component/>
    </Suspense>
);

const AllRoutes = () => {
    const {appSelector} = useRedux();

    const {layout} = appSelector((state) => ({
        layout: state.Layout,
    }));

    const getLayout = () => {
        let layoutCls: React.ComponentType = VerticalLayout;

        switch (layout.layoutType) {
            case LayoutTypes.LAYOUT_HORIZONTAL:
                layoutCls = HorizontalLayout;
                break;
            case LayoutTypes.LAYOUT_DETACHED:
                layoutCls = DetachedLayout;
                break;
            case LayoutTypes.LAYOUT_FULL:
                layoutCls = FullLayout;
                break;
            default:
                layoutCls = VerticalLayout;
                break;
        }
        return layoutCls;
    };
    let Layout = getLayout();

    return useRoutes([
        {path: '/', element: <Root/>},
        {
            // public routes
            path: '/',
            element: <DefaultLayout/>,
            children: [
                {
                    path: 'account',
                    children: [
                        {path: 'login', element: <LoadComponent component={Login}/>},
                        {path: 'register', element: <LoadComponent component={Register}/>},
                        {path: 'logout', element: <LoadComponent component={Logout}/>},
                        {path: 'confirm', element: <LoadComponent component={Confirm}/>},
                        {path: 'forget-password', element: <LoadComponent component={ForgetPassword}/>},
                        {path: 'lock-screen', element: <LoadComponent component={LockScreen}/>},
                    ],
                },
                {
                    path: 'error-404',
                    element: <LoadComponent component={ErrorPageNotFound}/>,
                },
                {
                    path: 'error-500',
                    element: <LoadComponent component={ServerError}/>,
                },
                {
                    path: 'maintenance',
                    element: <LoadComponent component={Maintenance}/>,
                },
                {
                    path: 'landing',
                    element: <LoadComponent component={Landing}/>,
                },
            ],
        },
        {
            // auth protected routes
            path: '/',
            element: <PrivateRoute roles={'Supervisor'} component={Layout}/>,
            // element: <Layout />,
            children: [
                {
                    path: 'dashboard', element: <LoadComponent component={Dashboard}/>
                },
                {
                    path: 'manageteam', element: <LoadComponent component={ManageTeam}/>
                },
                {
                    path: 'compensation', element: <LoadComponent component={Compensation}/>
                },
                {
                    path: 'activeloads', element: <LoadComponent component={ActiveLoads}/>
                },
                {
                    path: 'trip', element: <LoadComponent component={Broker}/>
                },
                {
                    path: 'trip/addtrip/broker/newbroker', element: <LoadComponent component={NewBroker}/>
                },
                {
                    path: 'trip/addtrip/broker/newcontact', element: <LoadComponent component={NewContact}/>
                },
                {
                    path: 'trip/addtrip/broker/findagent', element: <LoadComponent component={FindAgent}/>
                },
                {
                    path: 'trip/addtrip/broker/select', element: <LoadComponent component={SelectedBroker}/>
                },
                {
                    path: 'trip/addtrip/commodity', element: <LoadComponent component={Commodit}/>
                },
                {
                    path: 'trip/addtrip/pickup', element: <LoadComponent component={Pickup}/>
                },
                {
                    path: 'trip/addtrip/delivery', element: <LoadComponent component={Delivery}/>
                },
                {
                    path: 'trip/addtrip/submit', element: <LoadComponent component={Submit}/>
                },
                {
                    path: 'trip/activetrips/info', element: <LoadComponent component={Info}/>
                },
                {
                    path: 'trip/activetrips/route', element: <LoadComponent component={Route}/>
                },
                {
                    path: 'trip/activetrips/commodity', element: <LoadComponent component={Commodity}/>
                },
                {
                    path: 'trip/activetrips/permits', element: <LoadComponent component={Permits}/>
                },
                {
                    path: 'trip/activetrips/invoice', element: <LoadComponent component={Invo}/>
                },
                {
                    path: 'email',
                    children: [
                        {
                            path: 'inbox',
                            element: <LoadComponent component={Inbox}/>,
                        },
                        {
                            path: 'details',
                            element: <LoadComponent component={EmailDetail}/>,
                        },
                    ],
                },
                {
                    path: 'calendar',
                    element: <LoadComponent component={CalendarApp}/>,
                },
                {
                    path: 'chat',
                    element: <LoadComponent component={ChatApp}/>,
                },
                {
                    path: 'social',
                    element: <LoadComponent component={SocialFeed}/>,
                },
                {
                    path: 'paperwork',
                    element: <LoadComponent component={FileManager}/>,
                },
                {
                    path: 'faq',
                    element: <LoadComponent component={FAQ}/>,
                },
                {
                    path: 'social_profile',
                    element: <LoadComponent component={Profile2}/>,
                },
                {
                    path: 'dispatch_profile',
                    element: <LoadComponent component={Profile}/>,
                },
                {
                    path: 'driver_itinerary',
                    element: <LoadComponent component={ProjectGannt}/>,
                },
                {path: 'carrier_setup', element: <LoadComponent component={Carrier}/>},
                {path: '3rd_party_portals', element: <LoadComponent component={ThirdPartyPortal}/>},
                {path: 'active_trailers', element: <LoadComponent component={ActiveTrailers}/>},
                {path: 'active_trucks', element: <LoadComponent component={ActiveTrucks}/>},
                {path: 'active_leads', element: <LoadComponent component={ActiveLeads}/>},
                {path: 'active_drivers', element: <LoadComponent component={ActiveDrivers}/>},
                {path: 'active_driver/manage/:token', element: <LoadComponent component={ActiveDriverManage}/>}
            ],
        },
    ]);
};

export {AllRoutes};
