import { CommonLisTypes } from './constants';

type TruckActionType = {
    type:
        | CommonLisTypes.API_RESPONSE_SUCCESS
        | CommonLisTypes.API_RESPONSE_ERROR
        | CommonLisTypes.GET_CARRIERS
        | CommonLisTypes.SET_CARRIERS
        | CommonLisTypes.GET_OWNERS
        | CommonLisTypes.SET_OWNERS
    payload: {
        actionType?: string;
        data?: any | {};
        error?: string;
    };
    data?: [];
};

const INIT_STATE = {
    ownersList: [],
    carrierList:[],
    loading: false,
};

type StateType = {
    ownersList?: any | {};
    carrierList?: any | {};
    loading?: boolean;
};

const CommonList = (state: StateType = INIT_STATE, action: TruckActionType) => {
    switch (action.type) {
        case CommonLisTypes.API_RESPONSE_SUCCESS:

            switch (action.payload.actionType) {
                case CommonLisTypes.GET_OWNERS: {
                    return {
                        ...state,
                        ownersList: state.ownersList || [],
                        loading: false,
                    };
                }

                case CommonLisTypes.GET_CARRIERS: {
                    return {
                        ...state,
                        carrierList: state.carrierList || [],
                        loading: false,
                    };
                }

                default:
                    return { ...state };
            }


        case CommonLisTypes.GET_OWNERS: {
            return {
                ...state,
                ownersList: state.ownersList || [],
                loading: true,
            };
        }

        case CommonLisTypes.SET_OWNERS: {
            return {
                ...state,
                ownersList: action.data,
            };
        }

        case CommonLisTypes.GET_CARRIERS: {
            return {
                ...state,
                carrierList: state.carrierList || [],
                loading: true,
            };
        }

        case CommonLisTypes.SET_CARRIERS: {
            return {
                ...state,
                carrierList: action.data,
            };
        }

        default:
            return { ...state };
    }
};

export default CommonList;
