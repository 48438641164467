export enum TrailersActionTypes {
    API_RESPONSE_SUCCESS = '@@auth/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@auth/API_RESPONSE_ERROR',
    GET_TRAILERS = 'GET_TRAILERS',
    SET_TRAILERS = 'SET_TRAILERS',
    STORE_FILES = 'STORE_FILES',
    CREATE_TRAILER = 'CREATE_TRAILER',
    UPDATE_TRAILER = 'UPDATE_TRAILER',
    SET_INACTIVE_TRAILERS = 'SET_INACTIVE_TRAILERS',
    GET_INACTIVE_TRAILERS = 'GET_INACTIVE_TRAILERS',
    GET_SINGLE_TRAILER = 'GET_SINGLE_TRAILER',
    SET_SINGLE_TRAILER = 'SET_SINGLE_TRAILER',
    REMOVE_SINGLE_TRAILER = 'REMOVE_SINGLE_TRAILER',
}
