export type MenuItemType = {
    key: string;
    label: string;
    isTitle?: boolean;
    icon?: string;
    url?: string;
    badge?: {
        variant: string;
        text: string;
    };
    parentKey?: string;
    target?: string;
    children?: MenuItemType[];
};

const MENU_ITEMS: MenuItemType[] = [
    { 
        key: 'dashboard', 
        label: 'Dashboard', 
        isTitle: false,
        icon: 'uil-home-alt',
        url:'/dashboard'
    },
    { 
        key: 'manage-Team', 
        label: 'Manage Team', 
        isTitle: false,
        icon: 'uil-object-ungroup',
        url:'/manageteam'
    },
    { 
        key: 'compensation', 
        label: 'Compensation',
        isTitle: false,
        icon: 'uil-paypal',
        url:'/compensation'
    },
    { 
        key: 'active-loads', 
        label: 'Active loads',
        icon: 'uil-truck-loading',
        isTitle: false,
        url:'/activeloads'
    },
    {
        key: 'trip',
        label: 'Trip',
        isTitle: false,
        icon: 'uil-clipboard-alt',
        url:'/trip',
    },
    {
        key: 'email-inbox',
        label: 'Email',
        icon: 'uil-envelope',
        url: '/email/inbox',
        parentKey: 'apps-email',
    },

    {
        key: 'apps-calendar',
        label: 'Calendar',
        isTitle: false,
        icon: 'uil-calender',
        url: '/calendar',
    },

    {
        key: 'apps-chat',
        label: 'Chat',
        isTitle: false,
        icon: 'uil-comments-alt',
        url: '/chat',
    },
    {
        key: 'apps-social',
        label: 'Social Feed',
        isTitle: false,
        icon: 'uil-rss',
        url: '/social',
    },
    {
        key: 'apps-file-manager',
        label: 'Paperwork',
        isTitle: false,
        icon: 'uil-folder-plus',
        url: '/paperwork',
    },
    {
        key: 'page-faq', 
        label: 'FAQ',        
        icon: 'uil-question-circle',
        url: '/faq', 
        // parentKey: 'pages' 
    },
    {
        key: 'page-profile2',
        label: 'Social Profie',
        url: '/social_profile',
        // parentKey: 'pages',
    },
    {
        key: 'page-profile',
        label: 'Dispatch Profile',
        url: '/dispatch_profile',
        // parentKey: 'pages',
    },
    {
        key: 'project-gantt',
        label: 'Driver itinerary',
        url: '/driver_itinerary',
        // parentKey: 'apps-projects',
    },
    {
        key: 'project-create-project',
        label: 'Carrier setup',
        url: '/carrier_setup',
        // parentKey: 'apps-projects',
    },
    {
        key: '3rd-Party-portals',
        label: '3rd Party Portals',
        url: '/3rd_party_portals',
        // parentKey: 'apps-projects',
    },
    {
        key: 'Active-Trailers',
        label: 'Active Trailers',
        url: '/active_trailers',
        // parentKey: 'apps-projects',
    },
    {
        key: 'Active-Trucks',
        label: 'Active Trucks',
        url: '/active_trucks',
        // parentKey: 'apps-projects',
    },
    {
        key: 'Active-Leads',
        label: 'Active Leads',
        url: '/active_leads',
        // parentKey: 'apps-projects',
    },
    {
        key: 'Active-Drivers',
        label: 'Active Drivers',
        url: '/active_drivers',
        // parentKey: 'apps-projects',
    },
    // {
    //     key: 'page-error-500',
    //     label: 'Error - 500',
    //     url: '/error-500',
    //     parentKey: 'pages',
    // },
    // {
    //     key: 'page-error-404',
    //     label: 'Error - 404',
    //     url: '/error-404',
    //     parentKey: 'pages',
    // },

];

export { MENU_ITEMS };
