import { ActiveDriver } from './../../pages/apps/ActiveDrivers/model/activeDriver';
import { DriverActionTypes } from './constants';
import {ActiveTrucksType} from "../../pages/apps/ActiveTrucks/model/activeTruck";

type DriverActionType = {
    type:
        | DriverActionTypes.API_RESPONSE_SUCCESS
        | DriverActionTypes.API_RESPONSE_ERROR
        | DriverActionTypes.GET_DRIVER
        | DriverActionTypes.SET_DRIVER
        | DriverActionTypes.GET_INACTIVE_DRIVERS
        | DriverActionTypes.SET_INACTIVE_DRIVERS;
    payload: {
        actionType?: string;
        data?: ActiveDriver | {};
        error?: string;
    };
    data?: [];
};

const INIT_STATE = {
    drivers: [],
    inactiveDrivers:[],
    loading: false,
};

type StateType = {
    drivers?: ActiveDriver | {};
    inactiveDrivers?: ActiveTrucksType | {};
    loading?: boolean;
    value?: boolean;
};

const Drivers = (state: StateType = INIT_STATE, action: DriverActionType) => {

    switch (action.type) {

        case DriverActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case DriverActionTypes.GET_DRIVER: {
                    return {
                        ...state,
                        drivers: state.drivers || [],
                        loading: false,
                    };
                }

                default:
                    return { ...state };
            }

        case DriverActionTypes.GET_DRIVER: {
            return {
                ...state,
                loading: true,
            };
        }

        case DriverActionTypes.SET_DRIVER: {
            return {
                ...state,
                drivers: action.data,
            };
        }

        case DriverActionTypes.GET_INACTIVE_DRIVERS: {
            return {
                ...state,
                inactiveDrivers: state.inactiveDrivers || [],
                loading: true,
            };
        }

        case DriverActionTypes.SET_INACTIVE_DRIVERS: {
            return {
                ...state,
                inactiveDrivers: action.data,
            };
        }

        default:
            return { ...state };
    }
};

export default Drivers;
