import { LeadsActionTypes } from './constants';

export type LeadsActionType = {
    type:
        | LeadsActionTypes.API_RESPONSE_SUCCESS
        | LeadsActionTypes.API_RESPONSE_ERROR
        | LeadsActionTypes.GET_LEADS
        | LeadsActionTypes.SET_LEADS;
    payload: {} | string;
};

// common success
export const leadsApiResponseSuccess = (actionType: string, data: LeadsActionTypes | {}): LeadsActionType => ({
    type: LeadsActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

// common error
export const leadsApiResponseError = (actionType: string, error: string): LeadsActionType => ({
    type: LeadsActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getLeads = () => {
    return {
        type: LeadsActionTypes.GET_LEADS,
        payload: {},
    };
};

export const setLeads = (actionType: string, data: LeadsActionTypes | []): LeadsActionType => {
    return {
        type: LeadsActionTypes.SET_LEADS,
        payload: data,
    };
};
