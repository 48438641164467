function getPortals() {
    // const baseUrl = 'type url';
    // return api.create(`${baseUrl}`, params);
    return [
        {
            id: 1,
            name: 'Shreyu 1',
            url: 'url',
            userName: 'userName',
            note: 'note',
        },
        {
            id: 2,
            name: 'Shreyu 2',
            url: 'url',
            userName: 'userName',
            note: 'note',
        },
        {
            id: 3,
            name: 'Shreyu 3',
            url: 'url',
            userName: 'userName',
            note: 'note',
        },
    ];
}

export { getPortals };