import { TrailersActionTypes } from './constants';
import {ActiveTrailersType} from "../../pages/apps/ActiveTrailers/model/activeTrailer";

type TrailersActionType = {
    type:
        | TrailersActionTypes.API_RESPONSE_SUCCESS
        | TrailersActionTypes.API_RESPONSE_ERROR
        | TrailersActionTypes.GET_TRAILERS
        | TrailersActionTypes.SET_TRAILERS
        | TrailersActionTypes.SET_INACTIVE_TRAILERS
        | TrailersActionTypes.GET_INACTIVE_TRAILERS
        | TrailersActionTypes.SET_SINGLE_TRAILER
        | TrailersActionTypes.GET_SINGLE_TRAILER
        | TrailersActionTypes.REMOVE_SINGLE_TRAILER
    payload: {
        actionType?: string;
        data?: ActiveTrailersType | {};
        error?: string;
    };
    data?: [];
};

const INIT_STATE = {
    trailers: [],
    inactiveTrailers:[],
    singleTrailer:{},
    loading: false,
};

type StateType = {
    trailers?: ActiveTrailersType | {};
    inactiveTrailers?: ActiveTrailersType | {};
    singleTrailer?: ActiveTrailersType | {};
    loading?: boolean;
};

const Trailers = (state: StateType = INIT_STATE, action: TrailersActionType) => {
    switch (action.type) {
        case TrailersActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {

                case TrailersActionTypes.GET_TRAILERS: {
                    return {
                        ...state,
                        trailers: state.trailers || [],
                        loading: false,
                    };
                }

                case TrailersActionTypes.GET_SINGLE_TRAILER: {
                    return {
                        ...state,
                        singleTrailer: state.singleTrailer || [],
                        loading: false,
                    };
                }

                default:
                    return { ...state };
            }


        case TrailersActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case TrailersActionTypes.GET_TRAILERS: {
                    return {
                        ...state,
                        error: action.payload.error,
                        trailers: false,
                        loading: false,
                    };
                }

                case TrailersActionTypes.GET_SINGLE_TRAILER: {
                    return {
                        ...state,
                        singeTrailerError: action.payload.error,
                        loading: false,
                    };
                }

                case TrailersActionTypes.CREATE_TRAILER: {

                    return {
                        ...state,
                        createTrailerError: action.payload.error,
                        loading: false,
                    };
                }

                default:
                    return { ...state };
            }


        case TrailersActionTypes.GET_TRAILERS: {
            return {
                ...state,
                trailers: state.trailers || [],
                loading: true,
            };
        }

        case TrailersActionTypes.GET_INACTIVE_TRAILERS: {
            return {
                ...state,
                inactiveTrailers: state.inactiveTrailers || [],
                loading: true,
            };
        }

        case TrailersActionTypes.SET_INACTIVE_TRAILERS: {
            return {
                ...state,
                inactiveTrailers: action.data,
            };
        }

        case TrailersActionTypes.SET_TRAILERS: {
            return {
                ...state,
                trailers: action.data,
            };
        }

        case TrailersActionTypes.GET_SINGLE_TRAILER: {
            return {
                ...state,
                singleTrailer: state.singleTrailer || [],
                loading: true,
            };
        }

        case TrailersActionTypes.SET_SINGLE_TRAILER: {
            return {
                ...state,
                singleTrailer: action.data,
            };
        }

        case TrailersActionTypes.REMOVE_SINGLE_TRAILER: {
            return {
                ...state,
                singleTrailer:  {},
            };
        }

        default:
            return { ...state };
    }
};

export default Trailers;
