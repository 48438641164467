import { APICore } from './apiCore';

const api = new APICore();

function getDriver() {
    const baseUrl = 'drivers';
    return api.get(`${baseUrl}`);
}

function getInactiveDrivers() {
    const baseUrl = 'drivers/terminated';
    return api.get(`${baseUrl}`);
}
function createDriver(data: any) {
    const baseUrl = 'drivers';
    return api.create(`${baseUrl}`, data);
}
export { getDriver, getInactiveDrivers, createDriver };
