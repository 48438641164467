export enum TruckActionTypes {
    API_RESPONSE_SUCCESS = '@@auth/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@auth/API_RESPONSE_ERROR',
    GET_TRUCKS = 'GET_TRUCKS',
    SET_TRUCKS = 'SET_TRUCKS',
    CREATE_TRUCK = 'CREATE_TRUCK',
    SET_INACTIVE_TRUCKS = 'SET_INACTIVE_TRUCKS',
    GET_INACTIVE_TRUCKS = 'GET_INACTIVE_TRUCKS',
    GET_SINGLE_TRUCK = 'GET_SINGLE_TRUCK',
    SET_SINGLE_TRUCK = 'SET_SINGLE_TRUCK',
    REMOVE_SINGLE_TRUCK = 'REMOVE_SINGLE_TRUCK',
    UPDATE_TRUCK = 'UPDATE_TRUCK',
}
