import { SagaIterator } from '@redux-saga/core';
import {getCompletedLoads, getTrucks as getTrucksApi,} from 'helpers';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {LoadsActionTypes} from "./constants";
import {loadsApiResponseSuccess} from "./actions";
import {driverApiResponseSuccess} from "../driver/actions";

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* loadsInfo(): SagaIterator {
    try {
        const data = yield call(getTrucksApi);
        yield put({ type: LoadsActionTypes.SET_LOADS, data });

        yield put(loadsApiResponseSuccess(LoadsActionTypes.GET_LOADS, data?.data?.data));
    } catch (error: any) {}
}

function* completedLoadsInfo(): SagaIterator {
    try {
        const data = yield call(getCompletedLoads);
        yield put({type: LoadsActionTypes.SET_COMPLETED_LOADS, data});
        yield put(driverApiResponseSuccess(LoadsActionTypes.GET_COMPLETED_LOADS, data?.data?.data));
    } catch (error: any) {
        console.log('data err', error);
    }
}

export function* watchLoadsInfo() {
    yield takeEvery(LoadsActionTypes.GET_LOADS, loadsInfo);
}

export function* watchCompletedLoads(){
    yield takeEvery(LoadsActionTypes.GET_COMPLETED_LOADS, completedLoadsInfo)
}

function* loadsSaga() {
    yield all([fork(watchLoadsInfo),fork(watchCompletedLoads)]);
}

export default loadsSaga;
