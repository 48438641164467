import { CarrierActionTypes } from './constants';
import {CarrierType} from "../../pages/apps/Carrier/model/carrieSetup";

export type CarrierActionType = {
    type:
        | CarrierActionTypes.API_RESPONSE_SUCCESS
        | CarrierActionTypes.API_RESPONSE_ERROR
        | CarrierActionTypes.GET_CARRIER
        | CarrierActionTypes.SET_CARRIER;
    payload: {} | string;
};

// common success
export const carrierApiResponseSuccess = (actionType: string, data: CarrierActionTypes | {}): CarrierActionType => ({
    type: CarrierActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

// common error
export const carrierApiResponseError = (actionType: string, error: string): CarrierActionType => ({
    type: CarrierActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getCarrier = () => {
    return {
        type: CarrierActionTypes.GET_CARRIER,
        payload: {},
    };
};
export const setCarrier = (data : CarrierType): CarrierActionType  => {
    return {
        type: CarrierActionTypes.SET_CARRIER,
        payload: data,
    };
};
