import {SagaIterator} from '@redux-saga/core';
import {
    createDriver as createDriverApi,
    getDriver as getDriverApi,
    getInactiveDrivers
} from 'helpers';
import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import {driverApiResponseSuccess} from "./actions";
import {DriverActionTypes} from "./constants";


/**
 * Login the user
 * @param {*} payload - username and password
 */
function* driverInfo(): SagaIterator {
    try {
        const data = yield call(getDriverApi);
        yield put({type: DriverActionTypes.SET_DRIVER, data});
        yield put(driverApiResponseSuccess(DriverActionTypes.GET_DRIVER, data));
    } catch (error: any) {
    }
}

function* inactiveDriverInfo(): SagaIterator {
    try {
        const data = yield call(getInactiveDrivers);
        yield put({type: DriverActionTypes.SET_INACTIVE_DRIVERS, data});
        yield put(driverApiResponseSuccess(DriverActionTypes.GET_INACTIVE_DRIVERS, data?.data?.data));
    } catch (error: any) {
        console.log('data err', error);
    }
}

function* createDriver({payload}: any): SagaIterator {
    try {
        const response = yield call(createDriverApi, payload);
        console.log(response);
    } catch (error) {
        console.log(error)
    }
}

export function* watchDriverInfo() {
    yield takeEvery(DriverActionTypes.GET_DRIVER, driverInfo);
}

export function* watchCreateDriver() {
    yield takeEvery(DriverActionTypes.CREATE_DRIVER, createDriver)
}

function* driverSaga() {
    yield all([fork(watchDriverInfo), fork(inactiveDriverInfo), fork(watchCreateDriver)]);
}

export default driverSaga;
